<template>
  <Dialog v-if="value"
    :value="value"
    closeOnOk
    width="600px"
    height="500px"
    @close="$emit('input',$event)">
    <template #title>
      <span>交互信息</span>
    </template>
    <div v-if="cmdObj"
      class="CmdDetailContent">
      <div class="row">
        <span class="rowTitle">设备名称:</span>
        <span class="rowContent">{{cmdObj.plate}}</span>
      </div>
      <div class="row">
        <span class="rowTitle">终端号:</span>
        <span class="rowContent">{{cmdObj.terminalNo}}</span>
      </div>
      <div class="row">
        <span class="rowTitle">终端类型:</span>
        <span class="rowContent">{{cmdObj.terminalType}}</span>
      </div>
      <div class="row">
        <span class="rowTitle">车组:</span>
        <span class="rowContent">{{cmdObj.groupName}}</span>
      </div>
      <div class="row">
        <span class="rowTitle">下发类型:</span>
        <span class="rowContent">{{ getCmdTypeLabel(cmdObj.cmdType) || '其他参数' }}</span>
      </div>
      <div class="row">
        <span class="rowTitle">回复时间:</span>
        <span class="rowContent">{{dayjs(cmdObj.responseTime * 1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </div>
      <div class="row">
        <span class="rowTitle">状态:</span>
        <span class="rowContent">
          {{
          cmdObj.downStatus === -1
          ? ''
          : getCmdStatusLabel(cmdObj.downStatus)
          }}
        </span>
      </div>
      <div v-if="cmdObj.content"
        class="row">
        <div class="rowTitle">回复内容:</div>
        <div class="rowContent rowInfo">
          <div v-for="(str,i) in getMsgContents(cmdObj.content)"
            :key="i">{{str}}</div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import dayjs from 'dayjs';
import Dialog from '@/components/Dialog';
import { getCmdStatusLabel, getCmdTypeLabel } from '@/utils/cmdWebSocket';

export default {
  name: 'CmdDialogDetail',
  components: {
    Dialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cmdObj: {
      type: Object,
      default: null,
    }
  },
  methods: {
    dayjs,
    getCmdStatusLabel,
    getCmdTypeLabel,
    getMsgContents(content) {
      return content.split(';');
    }
  }
}
</script>

<style lang="scss" scoped>
.CmdDetailContent {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;

  overflow: auto;
}
.row {
  margin-bottom: 6px;
}
.rowTitle {
  font-weight: bold;
}
.rowContent {
  margin-left: 10px;
}
.rowInfo {
  margin-left: 60px;
}
</style>