<template>
  <div class="TerminalControlSide">
    <el-card :body-style="{padding:0}">
      <div slot="header">
        <span class="treeTitle">指令列表</span>
      </div>

      <el-tree
        highlight-current
        default-expand-all
        :expand-on-click-node="false"
        :data="treeData"
        @node-click="handleNodeClick"
      >
        <template v-slot="{node}">
          <div class="nodeCls">
            <Iconfont name="icon-xitongshezhi" :mRight="7"></Iconfont>
            <span>{{node.label}}</span>
          </div>
        </template>
      </el-tree>
    </el-card>
  </div>
</template>

<script>

import { cmdData } from './cmdData';

export default {
  name: 'TerminalControlSide',
  computed: {
    treeData() {
      return cmdData;
    }
  },
  methods: {
    handleNodeClick(data, node) {
      if (node.isLeaf) {
        this.$emit('node-click', data);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.TerminalControlSide {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.nodeCls {
  font-size: 14px;
  text-align: left;
  color: #1e1e1e;
  font-weight: 400;
}
.treeTitle {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #1e1e1e;
}

::v-deep.el-card {
  position: relative;
  width: 100%;
  height: 100%;
  .el-card__header {
    padding: 10px 20px;
  }
  .el-card__body {
    height: calc(100% - 40px);
    overflow: auto;
  }
}
</style>
