export const paramData = [
  { value: '0x0001', label: '终端心跳发送间隔，单位为秒(s)', checked: true },
  { value: '0x0002', label: 'TCP消息应答超时时间，单位为秒(s)', checked: true },
  { value: '0x0003', label: 'TCP消息重传次数', checked: true },
  { value: '0x0004', label: 'UDP消息应答超时时间，单位为秒(s)', checked: true },
  { value: '0x0005', label: 'UDP消息重传次数', checked: true },
  { value: '0x0006', label: 'SMS消息应答超时时间，单位为秒(s)', checked: true },
  { value: '0x0007', label: 'SMS消息重传次数', checked: true },
  { value: '0x0010', label: '主服务器APN，无线通信拨号访问点。若网络制式为CDMA，则该处为PPP拨号号码', checked: true },

  { value: '0x0011', label: '主服务器无线通信拨号用户名', checked: true },
  { value: '0x0012', label: '主服务器无线通信拨号密码', checked: true },
  { value: '0x0013', label: '主服务器地址，IP或域名，以冒号分割主机和端口，多个服务器使用分号分割', checked: true },
  { value: '0x0014', label: '备份服务器APN', checked: true },
  { value: '0x0015', label: '备份服务器无线通信拨号用户名', checked: true },
  { value: '0x0016', label: '备份服务器无线通信拨号密码', checked: true },
  { value: '0x0017', label: '备用服务器备份地址，IP或域名，以冒号分割主机和端口，多个服务器使用分号分割', checked: true },
  { value: '0x001A', label: '道路运输证1C卡认证主服务器IP地址或域名', checked: true },
  { value: '0x001B', label: '道路运输证1C卡认证主服务器TCP端口', checked: true },
  { value: '0x001C', label: '道路运输证1C卡认证主服务器UDP端口', checked: true },
  { value: '0x001D', label: '道路运输证1C卡认证备份服务器IP地址或域名，端口同主服务器', checked: true },
  { value: '0x0020', label: '位置汇报策略，0 :定时汇报;1:定距汇报;2 :定时和定距汇报', checked: true },
  { value: '0x0021', label: '位置汇报方案，0:根据ACC状态；1:根据登录状态和ACC状态，先判断登录状态，若登录再根据ACC状态', checked: true },
  { value: '0x0022', label: '驾驶员未登录汇报时间间隔，单位为秒(s)，值大于零', checked: true },
  { value: '0x0023', label: '从服务器APN。该值为空时，终端应使用主服务器相同配置', checked: true },
  { value: '0x0024', label: '从服务器无线通信拨号用户名。该值为空时，终端应使用主服务器相同配置', checked: true },
  { value: '0x0025', label: '从服务器无线通信拨号密码。该值为空时，终端应使用主服务器相同配置', checked: true },
  { value: '0x0026', label: '从服务器备份地址、IP或域名，主机和端口用冒号分割，多个服务器使用分号分割', checked: true },
  { value: '0x0027', label: '休眠时汇报时间间隔，单位为秒(s)，值大于0', checked: true },
  { value: '0x0028', label: '紧急报警时汇报时间间隔，单位为秒(s)，值大于0', checked: true },
  { value: '0x0029', label: '缺省时间汇报间隔，单位为秒(s)，值大于0', checked: true },
  { value: '0x002C', label: '缺省距离汇报间隔，单位为米（m)，值大于0', checked: true },
  { value: '0x002D', label: '驾驶员未登录汇报距离间隔，单位为米（m)，值大于0', checked: true },
  { value: '0x002E', label: '休眠时汇报距离间隔，单位为米（m)，值大于0', checked: true },
  { value: '0x002F', label: '紧急报警时汇报距离间隔，单位为米(m)，值大于0', checked: true },


  { value: '0x0030', label: '拐点补传角度，值小于180', checked: true },
  { value: '0x0031', label: '电子围栏半径(非法位移阈值），单位为米(m)', checked: true },
  { value: '0x0032', label: '违规行驶时段范围，精确到分。BYTEh违规行驶开始时间的小时部分;BYTE2：违规行驶开始时间的分钟部分;BYTE3：违规行驶结束时间的小时部分;BYTE4：违规行驶结束时间的分钟部分。示例:0xl6320AlE，表示当天晚上10点50分到第二天早上10点30分属于违规行驶时段', checked: true },
  { value: '0x0040', label: '监控平台电话号码', checked: true },
  { value: '0x0041', label: '复位电话号码，可采用此电话号码拨打终端电话让终端复位', checked: true },
  { value: '0x0042', label: '恢复出厂设置电话号码，可采用此电话号码拨打终端电话让终端恢复出厂设置', checked: true },
  { value: '0x0043', label: '监控平台SMS电话号码', checked: true },
  { value: '0x0044', label: '接收终端SMS文本报警号码', checked: true },
  { value: '0x0045', label: '终端电话接听策略。0:自动接听;1:ACC ON时自动接听，OFF时手动接听', checked: true },
  { value: '0x0046', label: '每次最长通话时间，单位为秒（s)，0为不允许通话，OxFFFFFFFF为不限制', checked: true },
  { value: '0x0047', label: '当月最长通话时间，单位为秒（s)，0为不允许通话，OxFFFFFFFF为不限制', checked: true },
  { value: '0x0048', label: '监听电话号码', checked: true },
  { value: '0x0049', label: '监管平台特权短信号码', checked: true },
  { value: '0x0050', label: '报警屏蔽字，与位置信息汇报消息中的报警标志相对应，相应位为1则相应报警被屏蔽', checked: true },
  { value: '0x0051', label: '报警发送文本SMS开关，与位置信息汇报消息中的报警标志相对应，相应位为1则相应报警时发送文本SMS', checked: true },
  { value: '0x0052', label: '报警拍摄开关，与位置信息汇报消息中的报警标志相对应，相应位为1则相应报警时摄像头拍摄', checked: true },
  { value: '0x0053', label: '报警拍摄存储标志，与位置信息汇报消息中的报警标志相对应，相应位为1则对相应报警时拍的照片进行存储，否则实时上传', checked: true },
  { value: '0x0054', label: '关键标志，与位置信息汇报消息中的报警标志相对应，相应位为1则对相应报警为关键报警', checked: true },
  { value: '0x0055', label: '最髙速度，单位为千米每小时（km/h)', checked: true },
  { value: '0x0056', label: '超速持续时间，单位为秒(S)', checked: true },

  { value: '0x0057', label: '连续驾驶时间门限，单位为秒(s)', checked: true },
  { value: '0x0058', label: '当天累计驾驶时间门限，单位为秒(s)', checked: true },
  { value: '0x0059', label: '最小休息时间，单位为秒(S)', checked: true },
  { value: '0x005A', label: '最长停车时间，单位为秒(S)', checked: true },
  { value: '0x005B', label: '超速预警差值，单位为1/10千米每小时（l/10km/h)', checked: true },
  { value: '0x005C', label: '疲劳驾驶预警差值，单位为秒(s)，值大于零', checked: true },
  { value: '0x005D', label: '碰撞报警参数设置：b7-b0:为碰撞时间，单位为毫秒（ms);bl5-b8为碰撞加速度，单位为0. lg;设置范围为0〜79，默认为10。', checked: true },
  { value: '0x005E', label: '侧翻报警参数设置:侧翻角度，单位为度（°)，默认为30°。', checked: true },
  { value: '0x0064', label: '定时拍照控制，参数项格式和定义见表14', checked: true },
  { value: '0x0065', label: '定距拍照控制，参数项格式和定义见表15', checked: true },
  { value: '0x0070', label: '图像/视频质量，设置范围为1〜10，1表示最优质量', checked: true },
  { value: '0x0071', label: '亮度，设置范围为0〜255', checked: true },
  { value: '0x0072', label: '对比度，设置范围为0〜127', checked: true },
  { value: '0x0073', label: '饱和度，设置范围为0〜127', checked: true },
  { value: '0x0074', label: '色度，设置范围为0〜255', checked: true },
  { value: '0x0080', label: '车辆里程表读数，单位:l/10km', checked: true },
  { value: '0x0081', label: '车辆所在的省域ID', checked: true },
  { value: '0x0082', label: '车辆所在的市域ID', checked: true },
  { value: '0x0083', label: '公安交通管理部门颁发的机动车号牌', checked: true },
  { value: '0x0084', label: '车牌颜色，值按照JT/T 697.7-2014中的规定，未上牌车辆填0', checked: true },
  { value: '0x0090', label: 'GNSS定位模式，定义如下；bit0,0:禁用GPS定位，1:启用GPS定位；bitl，0:禁用北斗定位，1:启用北斗定位；bit2,0:禁用GLONASS定位，1:启用 GLONASS 定位；bit3,0:禁用 Galileo定位，1:启用 Galileo定位', checked: true },
  { value: '0x0091', label: 'GNSS波特率，定义如下：0x00： 4800； 0x01： 9600； 0x02： 19200； 0x03： 38400； 0x04： 57600；0x05:115200', checked: true },
  { value: '0x0092', label: 'GNSS模块详细定位数据输出频率，定义如下：0x00:500ms; 0x01: 1000ms (默认值）； 0x02: 2000ms； 0x03: 3000ms；0x04 ： 4000ms', checked: true },
  { value: '0x0093', label: 'GNSS模块详细定位数据采集频率，单位为秒(s)，默认为1', checked: true },
  { value: '0x0094', label: 'GNSS模块详细定位数据上传方式：0x00,本地存储，不上传（默认值）；0x01，按时间间隔上传；0x02,按距离间隔上传；0x0B，按累计时间上传，达到传输时间后自动停止上传；0x0C,按累计距离上传，达到距离后自动停止上传；0x0D，按累计条数上传，达到上传条数后自动停止上传', checked: true },
  { value: '0x0095', label: 'GNSS模块详细定位数据上传设置:上传方式为0x01时，单位为秒(s);上传方式为0x02时，单位为米（m);上传方式为0x0B时，单位为秒(s);上传方式为0x0C时，单位为米（m);上传方式为0x0D时，单位为条', checked: true },
  { value: '0x0100', label: 'CAN总线通道1采集时间间隔，单位为毫秒(ms)，0表示不采集', checked: true },
  { value: '0x0101', label: 'CAN总线通道1上传时间间隔，单位为秒(s)，0表示不上传', checked: true },
  { value: '0x0102', label: 'CAN总线通道2采集时间间隔，单位为毫秒(ms)，0表示不采集', checked: true },
  { value: '0x0103', label: 'CAN总线通道2上传时间间隔，单位为秒(s)，0表示不上传', checked: true },
  { value: '0x0110', label: 'CAN总线ID单独采集设置：bit63-bit32表示此ID采集时间间隔（ms)，0表示不采集；bit31表示CAN通道号，0: CAN1,1: CAN2； bit30表示帧类型，0:标准帧，1:扩展帧;bit29表示数据采集方式，原始数据，1:采集区间的计算值；bit28-bitO表示CAN总线ID', checked: true },
  { value: '0x0111', label: '用于其他CAN总线ID单独采集设置', checked: true },
];