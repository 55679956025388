
/**
 * {
 *  label: 显示文本
 *  compName?: 组件名称 (目录和组件name一致, 叶子节点有)
 *  children: 子节点
 * }
 */

export const cmdData = [
  // {
  //   label: '调度下发',
  //   compName: 'CmdTextDown'
  // },
  // {
  //   label: '区域下发',
  //   children: [
  //     {
  //       label: '设置圆形区域',
  //       compName: 'CmdAreaSetCircle'
  //     },
  //     {
  //       label: '删除圆形区域',
  //       compName: 'CmdAreaDelCircle'
  //     },
  //     {
  //       label: '设置矩形区域',
  //       compName: 'CmdAreaSetRectangle'
  //     },
  //     {
  //       label: '删除矩形区域',
  //       compName: 'CmdAreaDelRectangle'
  //     },
  //     {
  //       label: '设置多边形区域',
  //       compName: 'CmdAreaSetPolygon'
  //     },
  //     {
  //       label: '删除多边形区域',
  //       compName: 'CmdAreaDelPolygon'
  //     },
  //     {
  //       label: '设置线路',
  //       compName: 'CmdAreaSetPath'
  //     },
  //     {
  //       label: '删除线路',
  //       compName: 'CmdAreaDelPath'
  //     },
  //     {
  //       label: '查询区域或线路数据',
  //       compName: 'CmdAreaOrPathQuery'
  //     }
  //   ]
  // },
  // {
  //   label: '终端控制',
  //   children: [
  //     {
  //       label: '断油电',
  //       compName: 'CmdOffOilElectricity'
  //     },
  //     {
  //       label: '同油电',
  //       compName: 'CmdOnOilElectricity'
  //     },
  //     {
  //       label: '终端复位',
  //       compName: 'CmdTerminalReset'
  //     }
  //   ]
  // },
  // {
  //   label: '终端升级',
  //   compName: 'CmdTerminalUpgrade'
  // },
  {
    label: '查询终端参数',
    compName: 'CmdQueryTerminalParameters',
  },
  // {
  //   label: '查询终端属性',
  //   compName: 'CmdQueryTerminalProperties',
  // },
  // {
  //   label: '存储多媒体数据检索',
  //   compName: 'CmdStoredMultimediaDataRetrieval',
  // },
  // {
  //   label: '存储多媒体数据上传',
  //   compName: 'CmdStoreMultimediaDataUpload',
  // },
  {
    label: '指定终端参数查询',
    compName: 'CmdQuerySpecifyTerminalParameters',
  },
  // {
  //   label: '数据下行透传',
  //   compName: 'CmdDataDownlinkTransparentTransmission',
  // },
  {
    label: '终端参数设置',
    children: [
      // {
      //   label: '其他参数设置',
      //   compName: 'CmdSetOtherParameters'
      // },
      {
        label: '网络配置',
        compName: 'CmdNetworkConfiguration',
      },
      {
        label: '回传配置',
        compName: 'CmdReturnConfiguration',
      },
      {
        label: '行驶参数',
        compName: 'CmdDrivingParameters',
      },
      // {
      //   label: '车辆里程表读数',
      //   compName: 'CmdVehicleOdometerReading',
      // },
      // {
      //   label: '电话回拨(监听)',
      //   compName: 'CmdCallBack',
      // },
      // {
      //   label: '定时拍照',
      //   compName: 'CmdTimedPhoto',
      // },
      // {
      //   label: '录音下发',
      //   compName: 'CmdRecordingRelease',
      // },
      // {
      //   label: '监听电话号码',
      //   compName: 'CmdMonitorPhoneNumber',
      // },
      // {
      //   label: '违规行驶时间段',
      //   compName: 'CmdViolationTimePeriod',
      // },
      // {
      //   label: '疲劳驾驶预警差值',
      //   compName: 'CmdFatigueDrivingWarningDifference',
      // },
      // {
      //   label: '车牌颜色',
      //   compName: 'CmdLicensePlateColor',
      // },
      // {
      //   label: '设置电话本',
      //   compName: 'CmdSetUpPhoneBook',
      // },
    ],
  },
  // {
  //   label: '主动安全参数设置',
  //   children: [
  //     {
  //       label: '主动安全参数查询',
  //       compName: 'CmdQueryADASParameters'
  //     },
  //     {
  //       label: '高级驾驶辅助系统参数设置',
  //       compName: 'CmdSetADASParameters'
  //     },
  //     {
  //       label: '驾驶员状态检测系统参数设置',
  //       compName: 'CmdSetDSMParameters'
  //     },
  //     {
  //       label: '盲区检测系统请求参数',
  //       compName: 'CmdBlindSpotDetectionParameters'
  //     }
  //   ]
  // }
];


