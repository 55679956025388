<template>
  <div class="TerminalControlWrapper">
    <Split direction="row"
      :sideOffset="0.25"
      :padding="0">
      <template #first>
        <!-- 侧边导航 -->
        <TerminalControlSide @node-click="onNodeClick"></TerminalControlSide>
      </template>
      <template #second>
        <div class="tableOuter">
          <el-table :data="tableData"
            size="mini"
            border
            stripe
            :row-class-name="tableRowClassName">
            <el-table-column width="80"
              label="操作">
              <template v-slot="{row}">
                <el-button type="primary"
                  size="mini"
                  @click="onClickDetail(row)">查看</el-button>
              </template>
            </el-table-column>

            <el-table-column type="index"
              width="60"
              label="序号"
              :index="onIndexRender"></el-table-column>

            <el-table-column prop="plate"
              label="设备名称"
              width="140"></el-table-column>
            <el-table-column prop="terminalNo"
              label="终端号"
              width="140"></el-table-column>
            <el-table-column prop="terminalType"
              label="终端类型"
              width="140"></el-table-column>
            <el-table-column prop="groupName"
              label="所属车组"
              width="140"></el-table-column>
            <el-table-column prop="cmdType"
              label="下发类型"
              width="180">
              <template v-slot="{row}">{{ getCmdTypeLabel(row.cmdType) || '其他参数' }}</template>
            </el-table-column>
            <el-table-column prop="downStatus"
              label="下发状态"
              width="180">
              <template v-slot="{row}">
                {{
                row.downStatus === -1
                ? ''
                : getCmdStatusLabel(row.downStatus)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="downTime"
              label="下发时间"
              width="160">
              <template v-slot="{row}">
                {{
                row.downTime > 0
                ? dayjs(row.downTime * 1000).format('YYYY-MM-DD HH:mm:ss')
                : ''
                }}
              </template>
            </el-table-column>
            <el-table-column prop="responseStatus"
              label="回复状态"
              width="180">
              <template v-slot="{row}">
                {{
                row.responseStatus === -1
                ? ''
                : getCmdStatusLabel(row.responseStatus)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="responseTime"
              label="回复时间"
              width="140">
              <template v-slot="{row}">
                {{
                row.responseTime > 0
                ? dayjs(row.responseTime * 1000).format('YYYY-MM-DD HH:mm:ss')
                :''
                }}
              </template>
            </el-table-column>
            <el-table-column prop="content"
              label="回复内容"
              width="540"
              show-overflow-tooltip></el-table-column>
          </el-table>
          <el-pagination background
            :current-page="pageObj.current"
            :page-size="pageObj.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="cmdMsgList.length"
            @current-change="onPageCurrentChange"></el-pagination>
        </div>
      </template>
    </Split>
    <component v-model="cmdDialogVisible"
      :is="cmdComponentName"
      @cmdMsg="onCmdMsg"></component>
    <CmdDialogDetail v-if="cmdDetail.visible"
      v-model="cmdDetail.visible"
      :cmdObj="cmdDetail.cmdObj"></CmdDialogDetail>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { isString } from 'lodash';
import Split from '@/components/Split';
import Dialog from '@/components/Dialog';
import TerminalControlSide from './TerminalControlSide.vue';
import CmdDialogDetail from './CmdDialogDetail.vue';

import { replaceAll } from '@/utils/replaceAll';
import { getCmdStatusLabel, getCmdTypeLabel } from '@/utils/cmdWebSocket';


// 读取指令组件, 并注册
const cmdComponentObj = {};
const cmdComponents = require.context(`./components`, true, /Cmd.*?\/index\.js/);
cmdComponents
  .keys()
  .forEach(fileName => {
    const cmdComponent = cmdComponents(fileName).default;
    cmdComponentObj[cmdComponent.name] = cmdComponent;
  });

export default {
  // `name`与路由保持一致
  name: 'TerminalControl',
  components: {
    Split,
    Dialog,
    TerminalControlSide,
    CmdDialogDetail,
    ...cmdComponentObj,
  },
  data() {
    this.cmdMsgMap = new Map();
    return {
      // 当前组件名称
      cmdComponentName: 'CmdTextDown',
      // Array<{
      // session,
      // vehicleId,
      // plate,
      // terminalNo,
      // terminalType,
      // groupName,
      // groupId,
      // cmdType,
      // time,
      // }>
      // 存储命令响应内容
      cmdMsgList: [],
      // 分页参数
      pageObj: {
        current: 1,
        pageSize: 100,
      },
      cmdDialogVisible: false,
      cmdDetail: {
        visible: false,
        cmdObj: null,
      }
    };
  },
  computed: {

    tableData() {
      const { cmdMsgList } = this;
      return cmdMsgList;
    }


  },
  beforeMount() {

  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    dayjs,
    getCmdStatusLabel,
    getCmdTypeLabel,
    onNodeClick(data) {
      this.cmdComponentName = data.compName;
      this.cmdDialogVisible = true;
    },
    onIndexRender(index) {
      const { pageObj } = this;
      return (pageObj.current - 1) * pageObj.pageSize + index + 1;
    },
    onPageCurrentChange(current) {
      this.pageObj.current = current;
    },
    getCmdMsgUid(obj) {
      return `${ obj.session }-${ obj.vehicleId }`;
    },
    onCmdMsg(obj) {
      const { cmdMsgMap } = this;
      try {

        const {
          palte: plate,
          terminalNO: terminalNo,
          content: contentStr,
          groudName: groupName,
          time,
          status,
          ...otherObj
        } = obj;

        let content = contentStr;
        if (isString(contentStr)) {
          content = contentStr.split('\\r\\n').join(';');
        }

        const msgRecord = {
          plate,
          terminalNo,
          groupName,
          content,
          ...otherObj
        };

        const cmdMsgId = this.getCmdMsgUid(msgRecord);

        // 更新
        if (cmdMsgMap.has(cmdMsgId)) {
          const oldRecord = cmdMsgMap.get(cmdMsgId);
          Object.assign(oldRecord, msgRecord);

          Object.assign(oldRecord, {
            successFlag: [0, 1].includes(status),
          });

          if ([0, 1].includes(status)) {
            Object.assign(oldRecord, {
              // 响应时间
              responseTime: time,
              // 响应状态
              responseStatus: status,
            });
          }

        } else {
          // 新增

          Object.assign(msgRecord, {
            // 下发时间
            downTime: time,
            // 下发状态
            downStatus: status,
            responseTime: 0,
            responseStatus: -1,
            successFlag: status === 1,
          });

          this.cmdMsgList.splice(0, 0, msgRecord);
          cmdMsgMap.set(cmdMsgId, msgRecord);
        }

      } catch (err) {
        console.error(err);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.successFlag) return 'success-row';
      return '';
    },
    onClickDetail(row) {
      this.cmdDetail.cmdObj = { ...row };
      this.cmdDetail.visible = true;
    }

  },

};
</script>

<style lang="scss" scoped>
.TerminalControlWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.tableOuter {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;

  display: flex;
  flex-direction: column;
}

::v-deep.el-table {
  // height: calc(100% - 100px);
  flex: 1;
  .el-table__body-wrapper {
    overflow-y: auto;
    height: calc(100% - 36px);
  }

  .success-row {
    background-color: #4fe16d !important;
    color: #000000;
    font-size: 12px;

    & > td.el-table__cell {
      background-color: #4fe16d !important;
    }
  }
}

::v-deep.el-pagination {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>