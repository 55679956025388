import { render, staticRenderFns } from "./CmdSetOtherParameters.vue?vue&type=template&id=0705c828&scoped=true"
import script from "./CmdSetOtherParameters.vue?vue&type=script&lang=js"
export * from "./CmdSetOtherParameters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0705c828",
  null
  
)

export default component.exports