/**
 * 去掉字符串前后指定字符
 */
export function trimsWith(value, str) {
  if (value.endsWith(str)) {
    value = value.substr(0, value.length - str.length);
  }
  if (value.startsWith(str)) {
    value = value.substr(str.length);
  }
  return value;
};