<template>
  <div v-show="false">
    <CmdDialog
      v-if="dialogVisible"
      :value="dialogVisible"
      width="520px"
      height="350px"
      groupCheckBox
      vehicleCheckBox
      :submit="submit"
      @close="$emit('input', $event)"
      @confirm="onConfirm"
      @clear="onClear"
    >
      <template #title>网络配置</template>
      <div class="CmdContent">
        <!-- 终端心跳间隔 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="devHeartInterval.checked">终端心跳间隔</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="devHeartInterval.value"
              :disabled="!devHeartInterval.checked"
              :min="10"
              :max="300"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8">(单位: 秒, 取值: 10 ~ 300)</el-col>
        </el-row>
        <!-- 主服务器APN -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="mainSrvAPN.checked">主服务器APN</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input
              v-model.trim="mainSrvAPN.value"
              :disabled="!mainSrvAPN.checked"
            ></el-input>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <!-- 主服务器地址 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="mainSrvIp.checked">主服务器地址</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input
              v-model.trim="mainSrvIp.value"
              :disabled="!mainSrvIp.checked"
            ></el-input>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>

        <!-- 服务器TCP端口 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="srvTcpPort.checked">服务器TCP端口</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="srvTcpPort.value"
              :disabled="!srvTcpPort.checked"
              :min="0"
              :max="65535"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>

        <!-- 服务器UDP端口 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="srvUpdPort.checked">服务器UDP端口</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="srvUpdPort.value"
              :disabled="!srvUpdPort.checked"
              :min="10"
              :max="65535"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </div>
    </CmdDialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import CmdDialog from '@/views/TerminalControl/CmdDialog.vue';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

//  网络配置
const cmdType = CmdType.internetParamSet;


// 设备心跳 10 ~ 30 秒
const default_devHeartInterval = Object.freeze({
  value: 10,
  checked: true
});
// 主服务器APN
const default_mainSrvAPN = Object.freeze({
  value: '',
  checked: true,
});
// 主服务器地址
const default_mainSrvIp = Object.freeze({
  value: '',
  checked: true,
});
// 服务器TCP端口
const default_srvTcpPort = Object.freeze({
  value: 7788,
  checked: true,
});
// 服务器UPD端口
const default_srvUpdPort = Object.freeze({
  value: 7780,
  checked: true,
});


export default {
  name: 'CmdNetworkConfiguration',
  components: {
    CmdDialog,
  },
  emits: [
    'cmdMsg'
  ],
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 选择的车辆
      vehicle: null,
      // 选择的车辆列表
      vehicleList: [],
      // 选择的车组列表
      groupList: [],

      // 设备心跳 10 ~ 30 秒
      devHeartInterval: cloneDeep(default_devHeartInterval),
      // 主服务器APN
      mainSrvAPN: cloneDeep(default_mainSrvAPN),
      // 主服务器地址
      mainSrvIp: cloneDeep(default_mainSrvIp),
      // 服务器TCP端口
      srvTcpPort: cloneDeep(default_srvTcpPort),
      // 服务器UPD端口
      srvUpdPort: cloneDeep(default_srvUpdPort)
    };
  },
  watch: {
    dialogVisible() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];

      this.devHeartInterval = cloneDeep(default_devHeartInterval);
      this.mainSrvAPN = cloneDeep(default_mainSrvAPN);
      this.mainSrvIp = cloneDeep(default_mainSrvIp);
      this.srvTcpPort = cloneDeep(default_srvTcpPort);
      this.srvUpdPort = cloneDeep(default_srvUpdPort);
    }
  },
  mounted() {
    if (cmdWsObj.request) {
      cmdWsObj.request.addMsg(cmdType, this.onCmdMsg);
    }
  },
  beforeDestroy() {
    if (cmdWsObj.request) {
      cmdWsObj.request.delMsg(cmdType, this.onCmdMsg);
    }
  },
  methods: {
    onClear() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    },
    onConfirm(groupList, vehicleList) {
      this.groupList = groupList;
      this.vehicleList = vehicleList;
    },
    async submit() {
      if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');

      // TODO: 判断条件是否满足

      const {
        devHeartInterval,
        mainSrvAPN,
        mainSrvIp,
        srvTcpPort,
        srvUpdPort,
        groupList,
        vehicleList
      } = this;

      const groupId = groupList.map(obj => obj.groupId).join(',');
      const vehicleId = vehicleList.map(obj => obj.vehicleId).join(',');

      if (!(groupId || vehicleId)) {
        return this.$message.warning('未选择车组或车辆');
      }

      const isFlag = [
        devHeartInterval,
        mainSrvAPN,
        mainSrvIp,
        srvTcpPort,
        srvUpdPort,
      ].some(obj => obj.checked);

      if (!isFlag) {
        return this.$message.warning('未选择参数');
      }

      const param = {
        devHeartInterval: -1,
        mainSrvAPN: '',
        mainSrvIp: '',
        srvTcpPort: -1,
        srvUpdPort: -1,
      };

      if (mainSrvAPN.checked) {
        if (!mainSrvAPN.value) return this.$message.warning('未设置主服务器APN');
        param.mainSrvAPN = devHeartInterval.value;
      }

      if (mainSrvIp.checked) {
        if (!mainSrvIp.value) return this.$message.warning('未设置主服务器地址');
        param.mainSrvIp = mainSrvIp.value;
      }

      if (devHeartInterval.checked) {
        param.devHeartInterval = devHeartInterval.value;
      }
      if (srvTcpPort.checked) {
        param.srvTcpPort = srvTcpPort.value;
      }
      if (srvUpdPort.checked) {
        param.srvUpdPort = srvUpdPort.value;
      }


      try {
        // TODO: 等待响应 使用`await`
        cmdWsObj
          .request
          .sendCmdAsync(cmdType, {
            groupId: groupId,
            vehicleId: vehicleId,
            param: param,
          });
      } catch (err) {
        console.error(err);
      }

      this.$message.info('指令已下发');
      return true;
    },
    // 指令响应信息
    onCmdMsg(obj) {
      try {
        const { contentType, vehicleInfo, ...otherObj } = obj;

        // TODO: 处理对应指令的内容

        this.$emit('cmdMsg', {
          contentType,
          ...otherObj,
          ...vehicleInfo
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CmdContent {
  position: relative;
}
.w100 {
  width: 100%;
}

::v-deep.el-row {
  align-items: center;
  margin-bottom: 10px;
}
</style>