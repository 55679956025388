<template>
  <div v-show="false">
    <CmdDialog
      v-if="dialogVisible"
      :value="dialogVisible"
      width="600px"
      height="500px"
      groupCheckBox
      vehicleCheckBox
      :submit="submit"
      @close="$emit('input', $event)"
      @confirm="onConfirm"
      @clear="onClear"
    >
      <template #title>指定终端参数查询</template>

      <div class="CmdContent">
        <div class="CmdContent_Main">
          <el-checkbox
            v-for="paramObj in checkList"
            v-model="paramObj.checked"
            :key="paramObj.value"
            :label="paramObj.label"
          ></el-checkbox>
        </div>
        <div class="CmdContent_Bottom">
          <el-button size="mini" @click="onChecklAll">全选</el-button>
          <el-button size="mini" @click="onCancelAll">取消全选</el-button>
        </div>
      </div>
    </CmdDialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import CmdDialog from '@/views/TerminalControl/CmdDialog.vue';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';
import { trimsWith } from '@/utils/trimsWith';
import { paramData } from './paramData';

const cmdType = CmdType.appointDevParamQuery;

export default {
  name: 'CmdQuerySpecifyTerminalParameters',
  components: {
    CmdDialog,
  },
  emits: [
    'cmdMsg'
  ],
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkList: cloneDeep(paramData),
      // 选择的车辆
      vehicle: null,
      // 选择的车辆列表
      vehicleList: [],
      // 选择的车组列表
      groupList: [],
    };
  },
  watch: {
    dialogVisible() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];

      this.checkList = cloneDeep(paramData);
    }
  },
  mounted() {
    if (cmdWsObj.request) {
      cmdWsObj.request.addMsg(cmdType, this.onCmdMsg);
    }
  },
  beforeDestroy() {
    if (cmdWsObj.request) {
      cmdWsObj.request.delMsg(cmdType, this.onCmdMsg);
    }
  },
  methods: {
    onChecklAll() {
      const { checkList } = this;
      checkList
        .forEach(obj => obj.checked = true);
    },
    onCancelAll() {
      const { checkList } = this;
      checkList
        .forEach(obj => obj.checked = false);
    },
    onClear() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    },
    onConfirm(groupList, vehicleList) {
      this.groupList = groupList;
      this.vehicleList = vehicleList;
    },
    async submit() {
      if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');
      const { groupList, vehicleList, checkList } = this;

      const groupId = groupList.map(obj => obj.groupId).join(',');
      const vehicleId = vehicleList.map(obj => obj.vehicleId).join(',');

      const param = checkList
        .filter(p => p.checked)
        .map(p => trimsWith(p.value, '0x')).join(',');

      if (!(groupId || vehicleId)) {
        return this.$message.warning('未选择车组或车辆');
      }

      if (!param) {
        return this.$message.warning('未选查询参数');
      }

      try {
        // TODO: 等待响应 使用`await`
        cmdWsObj
          .request
          .sendCmdAsync(cmdType, {
            groupId: groupId,
            vehicleId: vehicleId,
            param: param
          });
      } catch (err) {
        console.error(err);
      }

      this.$message.info('指令已下发');
      return true;
    },
    // 指令响应信息
    onCmdMsg(obj) {
      try {

        console.log(obj);

        const { contentType, vehicleInfo, ...otherObj } = obj;



        // TODO: 处理对应指令的内容

        this.$emit('cmdMsg', {
          contentType,
          ...otherObj,
          ...vehicleInfo
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CmdContent {
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
.CmdContent_Main {
  flex: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;
}
.CmdContent_Bottom {
  height: 50px;
  display: flex;
  align-items: center;
}
</style>