<template>
  <div v-show="false">
    <CmdDialog
      v-if="dialogVisible"
      :value="dialogVisible"
      width="520px"
      height="300px"
      :submit="submit"
      @close="$emit('input', $event)"
 @confirm="onConfirm"
      @clear="onClear"    >
      <template #title>录音下发</template>
      
    </CmdDialog>
  </div>
</template>

<script>
import CmdDialog from '@/views/TerminalControl/CmdDialog.vue';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

// TODO: 改成对应的命令类型
const cmdType = CmdType.devParamQuery;

export default {
  name: 'CmdRecordingRelease',
  components: {
    CmdDialog,
  },
   emits: [
    'cmdMsg'
  ],
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 选择的车辆
      vehicle: null,
      // 选择的车辆列表
      vehicleList: [],
      // 选择的车组列表
      groupList: [],
    };
  },
  watch: {
    dialogVisible() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    }
  },
  mounted() {
    if (cmdWsObj.request) {
      cmdWsObj.request.addMsg(cmdType, this.onCmdMsg);
    }
  },
  beforeDestroy() {
    if (cmdWsObj.request) {
      cmdWsObj.request.delMsg(cmdType, this.onCmdMsg);
    }
  },
  methods: {
onClear() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    },
    onConfirm(groupList, vehicleList) {
      // TODO: 处理选择车组车辆
    },
    async submit() {
      if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');

      // TODO: 判断条件是否满足


      try {
        // TODO: 等待响应 使用`await`
        cmdWsObj
          .request
          .sendCmdAsync(cmdType, {
            // TODO: 指令
            // groupId: '',
            // vehicleId: '',
            // param: {
            // }
          });
      } catch (err) {
        console.error(err);
      }

      this.$message.info('指令已下发');
      return true;
    },
    // 指令响应信息
    onCmdMsg(obj) {
      try {
        const { contentType, vehicleInfo, ...otherObj } = obj;

        // TODO: 处理对应指令的内容

        this.$emit('cmdMsg', {
          contentType,
          ...otherObj,
          ...vehicleInfo
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>