<template>
  <div v-show="false">
    <CmdDialog
      v-if="dialogVisible"
      :value="dialogVisible"
      width="600px"
      height="310px"
      groupCheckBox
      vehicleCheckBox
      :submit="submit"
      @close="$emit('input', $event)"
      @confirm="onConfirm"
      @clear="onClear"
    >
      <template #title>回传配置</template>
      <div class="CmdContent">
        <!-- 位置汇报策略 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="locationReportStrategy.checked">位置汇报策略</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-select
              class="w100"
              v-model="locationReportStrategy.value"
              :disabled="!locationReportStrategy.checked"
            >
              <el-option
                v-for="optObj in locationReportStrategyOptions"
                :key="optObj.value"
                :value="optObj.value"
                :label="optObj.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>

        <!-- 休眠汇报间隔 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="sleepReportInterval.checked">休眠汇报间隔</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="sleepReportInterval.value"
              :disabled="!sleepReportInterval.checked"
              :min="10"
              :max="300"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8">(单位: 秒, 取值: 10 ~ 300)</el-col>
        </el-row>
        <!-- 缺省时间汇报间隔 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="defaultTimeReportInterval.checked">缺省时间汇报间隔</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="defaultTimeReportInterval.value"
              :disabled="!defaultTimeReportInterval.checked"
              :min="10"
              :max="300"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8">(单位: 秒, 取值: 10 ~ 300)</el-col>
        </el-row>
        <!-- 缺省距离汇报间隔 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox
              v-model="defaultDistanceReportInterval.checked"
            >缺省距离汇报间隔</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="defaultDistanceReportInterval.value"
              :disabled="!defaultDistanceReportInterval.checked"
              :min="10"
              :max="50000"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8">(单位: 米, 取值: 10 ~ 50000)</el-col>
        </el-row>
        <!-- 休眠汇报距离间隔 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="sleepDistanceReportInterval.checked">休眠汇报距离间隔</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="sleepDistanceReportInterval.value"
              :disabled="!sleepDistanceReportInterval.checked"
              :min="100"
              :max="50000"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8">(单位: 秒, 取值: 10 ~ 50000)</el-col>
        </el-row>
      </div>
    </CmdDialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import CmdDialog from '@/views/TerminalControl/CmdDialog.vue';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

//  回传配置
const cmdType = CmdType.backParamSet;

const locationReportStrategyOptions = [
  { value: 0, label: '定时汇报' },
  { value: 1, label: '定距汇报' },
  { value: 2, label: '定时和定距汇报' }
];

// 位置汇报策略
const default_locationReportStrategy = Object.freeze({
  value: 0,
  checked: true,
});
// 休眠汇报间隔
const default_sleepReportInterval = Object.freeze({
  value: 10,
  checked: true,
});
// 缺省时间汇报间隔
const default_defaultTimeReportInterval = Object.freeze({
  value: 10,
  checked: true,
});
//  缺省距离汇报间隔
const default_defaultDistanceReportInterval = Object.freeze({
  value: 10,
  checked: true,
});
//  休眠汇报距离间隔
const default_sleepDistanceReportInterval = Object.freeze({
  value: 100,
  checked: true,
});


export default {
  name: 'CmdReturnConfiguration',
  components: {
    CmdDialog,
  },
  emits: [
    'cmdMsg'
  ],
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locationReportStrategyOptions,
      // 选择的车辆
      vehicle: null,
      // 选择的车辆列表
      vehicleList: [],
      // 选择的车组列表
      groupList: [],

      locationReportStrategy: cloneDeep(default_locationReportStrategy),
      sleepReportInterval: cloneDeep(default_sleepReportInterval),
      defaultTimeReportInterval: cloneDeep(default_defaultTimeReportInterval),
      defaultDistanceReportInterval: cloneDeep(default_defaultDistanceReportInterval),
      sleepDistanceReportInterval: cloneDeep(default_sleepDistanceReportInterval),

    };
  },
  watch: {
    dialogVisible() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];

      this.locationReportStrategy = cloneDeep(default_locationReportStrategy);
      this.sleepReportInterval = cloneDeep(default_sleepReportInterval);
      this.defaultTimeReportInterval = cloneDeep(default_defaultTimeReportInterval);
      this.defaultDistanceReportInterval = cloneDeep(default_defaultDistanceReportInterval);
      this.sleepDistanceReportInterval = cloneDeep(default_sleepDistanceReportInterval);
    }
  },
  mounted() {
    if (cmdWsObj.request) {
      cmdWsObj.request.addMsg(cmdType, this.onCmdMsg);
    }
  },
  beforeDestroy() {
    if (cmdWsObj.request) {
      cmdWsObj.request.delMsg(cmdType, this.onCmdMsg);
    }
  },
  methods: {
    onClear() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    },
    onConfirm(groupList, vehicleList) {
      this.groupList = groupList;
      this.vehicleList = vehicleList;
    },
    async submit() {
      if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');

      const {
        locationReportStrategy,
        sleepReportInterval,
        defaultTimeReportInterval,
        defaultDistanceReportInterval,
        sleepDistanceReportInterval,
        groupList,
        vehicleList
      } = this;

      const groupId = groupList.map(obj => obj.groupId).join(',');
      const vehicleId = vehicleList.map(obj => obj.vehicleId).join(',');

      if (!(groupId || vehicleId)) {
        return this.$message.warning('未选择车组或车辆');
      }

      const isFlag = [
        locationReportStrategy,
        sleepReportInterval,
        defaultTimeReportInterval,
        defaultDistanceReportInterval,
        sleepDistanceReportInterval,
      ].some(obj => obj.checked);

      if (!isFlag) {
        return this.$message.warning('未选择参数');
      }

      const param = {
        locationReportStrategy: -1,
        sleepReportInterval: -1,
        defaultTimeReportInterval: -1,
        defaultDistanceReportInterval: -1,
        sleepDistanceReportInterval: -1,
      };

      if (locationReportStrategy.checked) {
        param.locationReportStrategy = locationReportStrategy.value;
      }
      if (sleepReportInterval.checked) {
        param.sleepReportInterval = sleepReportInterval.value;
      }
      if (defaultTimeReportInterval.checked) {
        param.defaultTimeReportInterval = defaultTimeReportInterval.value;
      }
      if (defaultDistanceReportInterval.checked) {
        param.defaultDistanceReportInterval = defaultDistanceReportInterval.value;
      }
      if (sleepDistanceReportInterval.checked) {
        param.sleepDistanceReportInterval = sleepDistanceReportInterval.value;
      }


      try {
        // TODO: 等待响应 使用`await`
        cmdWsObj
          .request
          .sendCmdAsync(cmdType, {
            groupId: groupId,
            vehicleId: vehicleId,
            param: param
          });
      } catch (err) {
        console.error(err);
      }

      this.$message.info('指令已下发');
      return true;
    },
    // 指令响应信息
    onCmdMsg(obj) {
      try {
        const { contentType, vehicleInfo, ...otherObj } = obj;

        // TODO: 处理对应指令的内容

        this.$emit('cmdMsg', {
          contentType,
          ...otherObj,
          ...vehicleInfo
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CmdContent {
  position: relative;
}
.w100 {
  width: 100%;
}

::v-deep.el-row {
  align-items: center;
  margin-bottom: 10px;
}
</style>