<template>
  <div v-show="false">
    <CmdDialog
      v-if="dialogVisible"
      :value="dialogVisible"
      width="560px"
      height="400px"
      groupCheckBox
      vehicleCheckBox
      :submit="submit"
      @close="$emit('input', $event)"
      @confirm="onConfirm"
      @clear="onClear"
    >
      <template #title>行驶参数</template>
      <div class="CmdContent">
        <!-- 最高行驶速度 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="maxTravelSpeed.checked">最高行驶速度</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="maxTravelSpeed.value"
              :disabled="!maxTravelSpeed.checked"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :offset="1" :span="7">(单位: km/h)</el-col>
        </el-row>

        <!-- 超速报警预警差值 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="speedingThresholdDiff.checked">超速报警预警差值</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="speedingThresholdDiff.value"
              :disabled="!speedingThresholdDiff.checked"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :span="8">
            <el-select class="w120px" v-model="speedingThresholdDiffUnit">
              <el-option :value="10" label="10km"></el-option>
              <el-option :value="1" label="(1/10)km"></el-option>
            </el-select>
          </el-col>
        </el-row>

        <!-- 超速持续时间 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="speedingTime.checked">超速持续时间</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="speedingTime.value"
              :disabled="!speedingTime.checked"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :offset="1" :span="7">(单位: 秒)</el-col>
        </el-row>

        <!-- 连续驾驶时间门限 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="drivingTime.checked">连续驾驶时间门限</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="drivingTime.value"
              :disabled="!drivingTime.checked"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :offset="1" :span="7">(单位: 分钟)</el-col>
        </el-row>

        <!-- 最小休息时间 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="minRestTime.checked">最小休息时间</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="minRestTime.value"
              :disabled="!minRestTime.checked"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :offset="1" :span="7">(单位: 分钟)</el-col>
        </el-row>

        <!-- 最长停车时间 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="maxParkingTime.checked">最长停车时间</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100" type="number"
              v-model="maxParkingTime.value"
              :disabled="!maxParkingTime.checked" maxlength="9"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :offset="1" :span="7">(单位: 分钟)</el-col>
        </el-row>

        <!-- 疲劳驾驶预警差值 -->
        <el-row type="flex" justify="center">
          <el-col :offset="1" :span="7">
            <el-checkbox v-model="fatigueThresholdDiff.checked">疲劳驾驶预警差值</el-checkbox>
          </el-col>
          <el-col :span="8">
            <el-input-number
              class="w100"
              v-model="fatigueThresholdDiff.value"
              :disabled="!fatigueThresholdDiff.checked"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-col>
          <el-col :offset="1" :span="7">(单位: 分钟)</el-col>
        </el-row>
      </div>
    </CmdDialog>
  </div>
</template>

<script>

import { cloneDeep } from 'lodash';
import CmdDialog from '@/views/TerminalControl/CmdDialog.vue';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

// 行驶参数
const cmdType = CmdType.travelParamSet;

// 最高行驶速度
const default_maxTravelSpeed = Object.freeze({ value: 10, checked: true });
// 超速报警预警差值
const default_speedingThresholdDiff = Object.freeze({ value: 10, checked: true });
// 超速持续时间
const default_speedingTime = Object.freeze({ value: 10, checked: true });
// 连续驾驶时间门限
const default_drivingTime = Object.freeze({ value: 10, checked: true });
// 最小休息时间
const default_minRestTime = Object.freeze({ value: 10, checked: true });
// 最长停车时间
const default_maxParkingTime = Object.freeze({ value: 10, checked: true });
// 疲劳驾驶预警差值
const default_fatigueThresholdDiff = Object.freeze({ value: 10, checked: true });


// {
//   "maxTravelSpeed":"1",
// "speedingThresholdDiff":20,
// "speedingTime":"3",
// "drivingTime":240,
// "minRestTime":300,
// "maxParkingTime":360,
// "fatigueThresholdDiff":420,
// "preoverspeedtts":"",
// "overspeedtts":""}


export default {
  name: 'CmdDrivingParameters',
  components: {
    CmdDialog,
  },
  emits: [
    'cmdMsg'
  ],
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 选择的车辆
      vehicle: null,
      // 选择的车辆列表
      vehicleList: [],
      // 选择的车组列表
      groupList: [],

      // 超速报警预警差值 单位(10, 1/10)千米
      speedingThresholdDiffUnit: 10,

      maxTravelSpeed: cloneDeep(default_maxTravelSpeed),
      speedingThresholdDiff: cloneDeep(default_speedingThresholdDiff),
      speedingTime: cloneDeep(default_speedingTime),
      drivingTime: cloneDeep(default_drivingTime),
      minRestTime: cloneDeep(default_minRestTime),
      maxParkingTime: cloneDeep(default_maxParkingTime),
      fatigueThresholdDiff: cloneDeep(default_fatigueThresholdDiff),
    };
  },
  watch: {
    dialogVisible() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];

      this.maxTravelSpeed = cloneDeep(default_maxTravelSpeed);
      this.speedingThresholdDiff = cloneDeep(default_speedingThresholdDiff);
      this.speedingTime = cloneDeep(default_speedingTime);
      this.drivingTime = cloneDeep(default_drivingTime);
      this.minRestTime = cloneDeep(default_minRestTime);
      this.maxParkingTime = cloneDeep(default_maxParkingTime);
      this.fatigueThresholdDiff = cloneDeep(default_fatigueThresholdDiff);
    }
  },
  mounted() {
    if (cmdWsObj.request) {
      cmdWsObj.request.addMsg(cmdType, this.onCmdMsg);
    }
  },
  beforeDestroy() {
    if (cmdWsObj.request) {
      cmdWsObj.request.delMsg(cmdType, this.onCmdMsg);
    }
  },
  methods: {
    onClear() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    },
    onConfirm(groupList, vehicleList) {
      this.groupList = groupList;
      this.vehicleList = vehicleList;
    },
    async submit() {
      if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');

      const {
        groupList,
        vehicleList,

        speedingThresholdDiffUnit,

        maxTravelSpeed,
        speedingThresholdDiff,
        speedingTime,
        drivingTime,
        minRestTime,
        maxParkingTime,
        fatigueThresholdDiff,
      } = this;

      const groupId = groupList.map(obj => obj.groupId).join(',');
      const vehicleId = vehicleList.map(obj => obj.vehicleId).join(',');

      if (!(groupId || vehicleId)) {
        return this.$message.warning('未选择车组或车辆');
      }

      const isFlag = [
        maxTravelSpeed,
        speedingThresholdDiff,
        speedingTime,
        drivingTime,
        minRestTime,
        maxParkingTime,
        fatigueThresholdDiff,
      ].some(obj => obj.checked);

      if (!isFlag) {
        return this.$message.warning('未选择参数');
      }

      const param = {
        // 最高行驶速度
        maxTravelSpeed: -1,
        // 超速报警预警差值
        speedingThresholdDiff: -1,
        // 超速持续时间
        speedingTime: -1,
        // 连续驾驶时间门限 (秒)
        drivingTime: -1,
        // 最小休息时间 (秒)
        minRestTime: -1,
        // 最长停车时间 (秒)
        maxParkingTime: -1,
        // 疲劳驾驶预警差值 (秒)
        fatigueThresholdDiff: -1,

        // 预超速播报内容 自定义参数 不处理
        preoverspeedtts: '',
        // 超速播报内容 自定义参数 不处理
        overspeedtts: '',
      };

      if (maxTravelSpeed.checked) {
        param.maxTravelSpeed = Math.floor(maxTravelSpeed.value);
      }
      if (speedingThresholdDiff.checked) {
        param.speedingThresholdDiff
          = Math.floor(speedingThresholdDiff.value) * speedingThresholdDiffUnit;
      }
      if (speedingTime.checked) {
        param.speedingTime = Math.floor(speedingTime.value);
      }
      if (drivingTime.checked) {
        param.drivingTime = Math.floor(drivingTime.value) * 60;
      }
      if (minRestTime.checked) {
        param.minRestTime = Math.floor(minRestTime.value) * 60;
      }
      if (maxParkingTime.checked) {
        param.maxParkingTime = Math.floor(maxParkingTime.value) * 60;
      }
      if (fatigueThresholdDiff.checked) {
        param.fatigueThresholdDiff = Math.floor(fatigueThresholdDiff.value) * 60;
      }

      try {
        cmdWsObj
          .request
          .sendCmdAsync(cmdType, {
            groupId: groupId,
            vehicleId: vehicleId,
            param,
          });
      } catch (err) {
        console.error(err);
      }

      this.$message.info('指令已下发');
      return true;
    },
    // 指令响应信息
    onCmdMsg(obj) {
      try {

        console.log(obj);

        const { contentType, vehicleInfo, ...otherObj } = obj;

        this.$emit('cmdMsg', {
          contentType,
          ...otherObj,
          ...vehicleInfo
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CmdContent {
  position: relative;
}
.w100 {
  width: 100%;
}
.w120px {
  width: 120px;
}

::v-deep.el-row {
  align-items: center;
  margin-bottom: 10px;
}
</style>