import { render, staticRenderFns } from "./CmdRecordingRelease.vue?vue&type=template&id=3b8b167c&scoped=true"
import script from "./CmdRecordingRelease.vue?vue&type=script&lang=js"
export * from "./CmdRecordingRelease.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8b167c",
  null
  
)

export default component.exports