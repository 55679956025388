import { render, staticRenderFns } from "./CmdQueryTerminalParameters.vue?vue&type=template&id=4634ceac&scoped=true"
import script from "./CmdQueryTerminalParameters.vue?vue&type=script&lang=js"
export * from "./CmdQueryTerminalParameters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4634ceac",
  null
  
)

export default component.exports