<template>
  <div v-show="false">
    <CmdDialog v-if="dialogVisible"
      :value="dialogVisible"
      width="400px"
      height="80px"
      :submit="submit"
      placeholder="设备名称/终端号"
      noGroup
      @close="$emit('input', $event)"
      @confirm="onConfirm"
      @clear="onClear">
      <template #title>查询终端参数</template>
    </CmdDialog>
  </div>
</template>

<script>
import CmdDialog from '@/views/TerminalControl/CmdDialog.vue';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

// TODO: 改成对应的命令类型
const cmdType = CmdType.devParamQuery;

export default {
  name: 'CmdQueryTerminalParameters',
  emits: [
    'cmdMsg'
  ],
  components: {
    CmdDialog,
  },
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vehicle: null,
    };
  },
  watch: {
    dialogVisible() {
      this.vehicle = null;
    }
  },
  mounted() {

    if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');

    cmdWsObj.request.addMsg(cmdType, this.onCmdMsg);
  },
  beforeDestroy() {
    if (cmdWsObj.request) {
      cmdWsObj.request.delMsg(cmdType, this.onCmdMsg);
    }
  },
  methods: {
    onClear() {
      this.vehicle = null;
      this.vehicleList = [];
      this.groupList = [];
    },
    onConfirm(groupList, vehicleList) {
      if (vehicleList.length) {
        this.vehicle = vehicleList[0];
      }
    },
    async submit() {
      const { vehicle } = this;
      if (!cmdWsObj.request) return this.$message.warning('命令链路未连接');
      if (!vehicle) return this.$message.warning('请选择车辆');

      const { groupId, vehicleId } = vehicle;

      try {
        cmdWsObj
          .request
          .sendCmdAsync(cmdType, {
            groupId: '',
            vehicleId,
            param: {
              paramNum: 0
            }
          });
      } catch (err) {
        console.error(err);
      }

      this.$message.info('指令已下发');
      return true;
    },
    onCmdMsg(obj) {
      console.log(obj);

      try {
        const { contentType, vehicleInfo, ...otherObj } = obj;

        // TODO: 在这里处理数据

        this.$emit('cmdMsg', {
          contentType,
          ...otherObj,
          ...vehicleInfo
        });

      } catch (err) {
        console.log(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>