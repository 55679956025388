<template>
  <Dialog v-if="value"
    v-bind="$attrs"
    :value="value"
    :submit="submit"
    @close="onClose">
    <template #title>
      <slot name="title"></slot>
    </template>
    <div class="CmdContentLayout">
      <SelectGroupTree :singleGroup="singleGroup"
        :multipleProp="multipleProp"
        :placeholder="placeholder"
        @on-select="onSelect"
        @on-check="nodeCheck"
        @on-vehicle="onVehilce"
        @on-confirm="onConfirm"
        @clear="onClear"></SelectGroupTree>

      <template v-if="$slots.default">
        <div class="CmdDialog_CmdContent">
          <slot></slot>
        </div>
      </template>
    </div>
  </Dialog>
</template>

<script>
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import Dialog from '@/components/Dialog';

export default {
  name: 'CmdContentLayout',
  emits: [
    'confirm',
  ],
  components: {
    Dialog,
    SelectGroupTree,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // true: 不选择车组
    noGroup: {
      type: Boolean,
      default: false,
    },
    // 车组多选
    groupCheckBox: {
      type: Boolean,
      default: false,
    },
    // 车辆多选
    vehicleCheckBox: {
      type: Boolean,
      default: false
    },
    // 是否只展示组织
    singleGroup: {
      type: Boolean,
      default: false,
    },
    // 提交回调函数
    submit: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: '车组/设备名称/终端号'
    }
  },

  computed: {
    // 参数配置
    multipleProp() {
      const { noGroup, groupCheckBox, vehicleCheckBox } = this;
      return {
        noGroup,
        groupCheckBox,
        vehicleCheckBox
      };
    }
  },
  methods: {
    onSelect(item) {

      // 1:"组织",2:"车辆",3:"驾驶员"
      const { type } = item;
      if (type === 1) {
        this.triggerConfirm([item], []);
      }
      if (type === 2) {
        this.triggerConfirm([], [item]);
      }
    },
    nodeCheck(...args) {
    },
    onConfirm({ groupList, vehicleList }) {
      this.triggerConfirm(groupList, vehicleList);
    },
    onVehilce(vehicle) {
      this.triggerConfirm([], [vehicle]);
    },
    triggerConfirm(groupList = [], vehicleList = []) {
      this.$emit('confirm', groupList, vehicleList);
    },
    onClose() {
      this.$emit('close', false);
      this.$emit('input', false);
    },
    onClear() {
      this.$emit('clear');
    }
  }
}
</script>

<style lang="scss" scoped>
.CmdContentLayout {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 25px 15px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.CmdDialog_CmdContent {
  position: relative;
  flex: 1;
  margin-top: 25px;
  overflow: hidden;
}
</style>
